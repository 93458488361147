import humanize from 'humanize'
import numeral from 'numeral'
import storeService from '@/store'
export * from '@shared/utils/filters'

export default function(app) {
  // No-op; we eliminated vue filter use for vue 3
}

storeService.observe((store) => {
  const bytesScaleAccessor = () => store.get('settings/bytescale')
  const byteScaleSetting = bytesScaleAccessor()
  if (byteScaleSetting) {
    byteScale(byteScaleSetting)
  }
  store.watch(
    bytesScaleAccessor,
    (_) => {
      byteScale(_)
    }
  )
})

// Humanizing some common seconds
export function humanizeSeconds(s) {
  switch (+s) {
    case 60:
      return 'One minute'
    case 300:
      return '5 minutes'
    case 600:
      return '10 minutes'
    case 900:
      return '15 minutes'
    case 1800:
      return 'Half hour'
    case 3600:
      return '1 hour'
    case 7200:
      return '2 hours'
    case 21600:
      return '6 hours'
    case 43200:
      return '12 hours'
    case 86400:
      return '1 day'
    case 259200:
      return '3 days'
    case 604800:
      return '1 week'
    case 2592000:
      return '1 month'
    case 5184000:
      return '2 months'
  }
  return '{0} seconds'.replace(/\{0\}/, numeral(s).format('0,0'))
}

// Humanizing an elapsed time
export function humanizeElapsedTime(s) {
  return humanize.relativeTime(humanize.time() - parseInt(s / 1000))
}

export function smcNiceFilter(val, id) {
  if (id.match(/_ms$/)) {
    return humanizeMs(val)
  } else if (id.match(/memory|data|bytes/gi) || id.match(/(read|write|spill).*size/gi)) {
    return bytes(val)
  } else if (typeof val === 'number') {
    return numeral(val).format('0,0')
  } else {
    return val
  }
}

// Join that thang
export function join(input, delimiter) {
  return Array.isArray(input) ? input.join(delimiter || ',') : input
}

export function slugify(value, params) {
  // console.log(value
  //   .toString()
  //   .normalize('NFD')
  //   .replace(/[\u0300-\u036f]/g, '')
  //   .toLowerCase()
  //   .trim()
  //   .replace(/[^a-z0-9\s-]/g, '')
  //   .replace(/\s+/g, '-'))

  // console.log(value
  //   .toString()
  //   .normalize('NFD')
  //   .replace(/[\u0300-\u036f]/g, '')
  //   .toLowerCase()
  //   .trim()
  //   .replace(/[^a-z0-9 ]/g, ''))

  // console.log(value
  //   .toString()
  //   .normalize('NFD')
  //   .replace(/[\u0300-\u036f]/g, '')
  //   .toLowerCase()
  //   .trim())

  // console.log(value
  //   .toString()
  //   .normalize('NFD')
  //   .replace(/[\u0300-\u036f]/g, ''))

  return value
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\s-]/g, '')
    .replace(/\s+/g, '-')
}
