<template>
  <transition name="dialog">
    <yb-modal
      :show="show"
      :title="title"
      type="form"
      :icon="icon"
      :dialog-classes="dialogClasses"
      @close="onClose"
      @submit="okClick"
    >
      <div class="yb-view">
        <div class="yb-view-content">
          <slot />
        </div>

        <div class="yb-view-footer flex flex-row justify-end">
          <button v-translate class="yb-button-primary" type="submit" :disabled="!valid">
            {{ okActualLabel }}
          </button>
        </div>
      </div>
    </yb-modal>
  </transition>
</template>

<script>
import YbDialogMixin from './YbDialogMixin'

export default {
  name: 'YbModalDialog',
  mixins: [YbDialogMixin],
  props: {
    closeLabel: String,
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    okActualLabel() {
      return this.closeLabel || 'Close'
    }
  }
}
</script>
