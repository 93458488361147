import { confirmModal } from '@/utils/misc/modal'

async function confirmClickHandler(event) {
  event.preventDefault()
  try {
    await confirmModal(this.config.title || 'Yellowbrick Manager', this.config.message || 'Are you sure you want to do perform this operation?')
    this.config.onConfirm(true)
  } catch (ignore) {
  }
}

export default function(app) {
  app.directive('yb-confirm', {
    beforeMount(el, binding, vnode) {
      const attrs = vnode.props || {}
      let config
      if (typeof binding.value === 'object') {
        config = binding.value
      } else if (typeof binding.value === 'function') {
        config = Object.assign({ onConfirm: binding.value }, attrs)
      } else if (binding.value) {
        throw new Error('Unsupported v-yb-confirm value: ' + binding.value)
      } else {
        config = Object.assign({}, attrs)
      }

      el.addEventListener('click', (el.__confirm_listener = confirmClickHandler.bind({ config })))
    },
    unmounted(el, binding, vnode) {
      el.removeEventListener('click', confirmClickHandler.__confirm_listener)
      delete el.__confirm_listener
    }
  })
}
