import validate from "/opt/ybd/node_modules/nuxt/dist/pages/runtime/validate.js";
import jolokia_45global from "/opt/ybd/ui/ym/src/middleware/jolokia.global.ts";
import license_45global from "/opt/ybd/ui/ym/src/middleware/license.global.ts";
import load_45global from "/opt/ybd/ui/ym/src/middleware/load.global.ts";
import manifest_45route_45rule from "/opt/ybd/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  jolokia_45global,
  license_45global,
  load_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/ybd/ui/ym/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}