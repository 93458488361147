import { type RouteLocationNormalized } from 'vue-router'
import { useDark } from '@vueuse/core'
import { clearDialogsAndToasts } from '@shared/utils/misc/dialogs'

useDark({
  storageKey: 'YM.theme',
  valueDark: 'dark',
  valueLight: 'light',
  initialValue: 'auto'
})

const logger = createLogger('ym:auth:middleware')

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (process.client) {
    // Update the dialog visibility
    clearDialogsAndToasts()

    // Check authenticated status; if we're not logged in and need to be, go to login.
    if (to.meta?.auth !== false && to.path !== '/login' && to.path !== from.path) {
      logger.trace('Calling getSession b/c of route change to', to)
      const { getSession } = useAuth()
      try {
        await getSession()
      } catch (ignore) {
        // We are just trying to renew session token here
      }
    }
  }
})
