import clipboard from './clipboard'
import confirm from './confirm'
import resize from './resize'
import documentClassWatcher from './document-class-watcher'

export function install(app) {
  app.use(clipboard)
  app.use(confirm)
  app.use(resize)
  app.use(documentClassWatcher)
}
