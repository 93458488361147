import { alertModal } from '@shared/utils/misc/modal'
import { app as appService, errorHandler } from '@/services'
import ErrorReport from '@/services/ResponseErrorComponent.vue'

// Setup error handler and loading interceptors.
errorHandler.setErrorHandler((title: string, message: string) => {
  return alertModal(title, message)
})
errorHandler.setErrorResultHandler(appService.errorResult)

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('vue:error', (error, vm, info) => {
    // err: error trace
    // vm: component in which error occurred
    // info: vue specific error information such as lifecycle hooks, events etc.
    console.error('[ym:component] Unexpected exception in component', vm, 'info', info)

    // We handle erros through central handler.
    if (error instanceof Error) {
      errorHandler.thrown(error, 'Unexpected exception in component.')
    } else {
      console.error(error)
    }
  })

  nuxtApp.hook('apollo:error', async (errorResponse, ...args) => {
    let message = 'GraphQL Error'
    let stack
    if (errorResponse.graphQLErrors && errorResponse.graphQLErrors.length) {
      message = errorResponse.graphQLErrors[0].message
      stack = errorResponse.graphQLErrors[0].stack || errorResponse.graphQLErrors[0].extensions?.stacktrace
      if (Array.isArray(stack)) {
        stack = stack.join('\n')
      }
    }
    const error = new Error(message)
    if (stack) {
      error.stack = stack
    }
    const silent = errorResponse.operation.variables?.silent === true
    if (!silent) {
      await useDialogs().component('Error', 'Close', ErrorReport, { error, message: 'Unexpected server error', args }, null, { dataTestId: 'response-error' })
    }
  })
})
