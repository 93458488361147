import { createConsola, type ConsolaInstance, type LogObject } from 'consola'

let instance: ConsolaInstance|null = null
export const createLogger = (category: string): ConsolaInstance => {
  if (!instance) {
    instance = createConsola({
      formatOptions: {
        date: true,
        colors: false,
        compact: false
      },
      throttleMin: 50,
      reporters: [
        {
          log: (logObj: LogObject) => {
            let msg = `${logObj.date.toISOString()} | ${(logObj.type || '').toUpperCase().padEnd(8, ' ')} | ${(logObj.tag || '').padEnd(20, ' ')} | `
            const args: any[] = Array.isArray(logObj.args) ? logObj.args : []
            if (args.length > 0 && typeof args[0] === 'string') {
              msg += args.shift()
            }
            ((console as any)[logObj.type] || console.log)(msg, ...args)
          }
        }
      ]
    })
  }
  return instance.withTag(category)
}
