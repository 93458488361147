// Adapted from: https://stackblitz.com/edit/vue3-instantiate-component-v8ecmy

import { createVNode, render, getCurrentInstance } from 'vue'

export default function renderComponent({ el, component, props, parentComponent, appContext }) {
  // Resolve current app context if not given.
  if (!appContext) {
    if (parentComponent) {
      parentComponent = parentComponent.appContext
    } else {
      appContext = getCurrentInstance()
    }
  }

  // Create the vnode, wire the context, and render.
  let vnode = createVNode(component, props)
  vnode.appContext = { ...appContext } // must spread new object here
  render(vnode, el)

  // Set parent.  This DOES NOT work.  It will cause strange internal vue errors during render, so don't try.
  if (false) {
    if (parentComponent) {
      vnode.component.parent = parentComponent
    }
  }

  // Return vnode, component instance and a fn that lets the component be destroyed.
  return {
    vnode,
    component: vnode.component.proxy,
    destroy: () => {
      render(null, el)
      vnode = undefined
    }
  }
}

// Rendering helper.
export function install(app) {
  app.$renderComponent = app.config.globalProperties.$renderComponent = (el, component, props, parentComponent) => {
    return renderComponent({ el, component, props, parentComponent, appContext: parentComponent?.appContext || app._context })
  }
}
