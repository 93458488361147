<template>
  <yb-modal-dialog show icon="fa-exclamation-triangle" :title="translate('Clipboard Access Denied')" :ok="copyAndClose" :close-label="translate('Copy')">
    <div class="notification is-warning">
      <p v-translate>
        The text could not be copied to the clipboard.
        <br><br>
        Please click the "Copy" button to grant access to the clipboard.
      </p>
    </div>
  </yb-modal-dialog>
</template>

<script>
import { translate } from '../utils/filters'

export default {
  props: {
    text: String
  },
  methods: {
    translate,
    copyAndClose() {
      this.$copyText(this.text)
      this.$dialogs.notifyClipboard(this.text)
      return true
    }
  }
}
</script>
