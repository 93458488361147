<template>
  <div class="h-full overflow-hidden yb">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <transition name="drawer">
      <yb-modal
        v-if="showSamples"
        :show="showSamples"
        title="Sample Data Catalog"
        type="form"
        layout="drawer"
        dialog-classes=""
        drawer-width="yb-side-md"
        data-test-id="sample_data_catalog_header"
        @close="showSamples = false"
      >
        <yb-sample-data :show="showSamples" @close="showSamples = false" />
      </yb-modal>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import Instance from '@/models/Instance'
import storeService from '@/store/storeService'
import { clearCommunicationsError } from '@/services/app'

export default {
  components: {
    YbSampleData: defineAsyncComponent(() => import('@/app/sample/SampleData.vue'))
  },
  data() {
    return {
      showSamples: false
    }
  },
  computed: {
    toggles() {
      return this.$store.state.settings.toggles
    }
  },
  watch: {
    toggles() {
      this.updateToggles()
    }
  },
  mounted() {
    this.updateToggles()
    this.$root.showSampleCatalog = _event =>
      (this.showSamples = true)
  },
  created() {
    this.$router.afterEach((to, from) => {
      Object.keys(to.query).forEach((toggleName) => {
        if (this.$toggles[toggleName] !== undefined) {
          this.$toggles[toggleName] = (to.query[toggleName] === 'true')
        }
      })

      // If we are changing instances, clear communications errors.
      if (!!to && !!from) {
        if (!to.path.match(/instance/) || !from.path.match(/instance/) || to.params.instance_id !== from.params.instance_id) {
          clearCommunicationsError()
        }
        clearCommunicationsError(true)
      }
    })

    // If we are navigating TO the data wizard, dump the cache.
    this.$router.beforeEach(
      (to, from, next) => {
        if (String(to?.path).match(/data-wizard/) && !String(from?.path).match(/data-wizard/)) {
          storeService.get().dispatch('data-wizard/invalidate')
        }

        next()
      }
    )

    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'instance/populate' && state.instance.userInstances) {
          const oldInstanceIds = Instance.query().get().map(i => i.id)

          // Integrate new instance ids.
          Instance.insertOrUpdate({
            data: state.instance.userInstances
          })

          // Delete any old instance ids that have disappeared.
          const newInstanceIds = new Set(state.instance.userInstances.map(i => i.id))
          oldInstanceIds
            .filter(id => !newInstanceIds.has(id))
            .forEach(id => Instance.delete(id))
        }
      }
    })
  },
  methods: {
    updateToggles() {
      Object.assign(this.$toggles, { ...this.$store.state.settings.toggles })
    }
  }
}
</script>

<style lang="postcss">
#__nuxt {
  @apply h-full w-full;
}
</style>
