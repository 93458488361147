import { type RouteLocationNormalized } from 'vue-router'
import useLicense from '@/utils/license'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (process.client) {
    const { status } = useAuth()

    // Check authenticated status; if we're authenticated, get the license info.
    if (status.value === 'authenticated' && to.path !== '/login') {
      useLicense().refreshLicense(to?.params?.instance as string|null)
    }
  }
})
