import { Fragment, h } from 'vue'

export const Tabs = {
  name: "tabs",
  props: {
    defaultIndex: {
      default: 0,
      type: Number
    },
    onSelect: {
      type: Function
    }
  },
  data() {
    return {
      selectedIndex: this.defaultIndex
    }
  },
  methods: {
    switchTab(e, index, isDisabled) {
      if (!isDisabled) {
        this.selectedIndex = index
        this.onSelect && this.onSelect(e, index)
      }
    }
  },
  render() {
    const defaultSlot = this.$slots.default()
    if (!defaultSlot.length) {
      return h('div')
    }
    const tabs = (defaultSlot[0].type === Fragment ? defaultSlot[0].children : this.$slots.default()).filter(
      component => component.props
    )

    const tabList = []
    tabs.forEach((child, index) => {
      const { title, titleSlot, disabled } = child.props
      const dataTestId = child.props['data-test-id']
      const content = titleSlot ? this.$slots[titleSlot] : title
      const isDisabled = disabled === true || disabled === ""
      const tabOptions = {}
      if (dataTestId) {
        tabOptions['data-test-id'] = `tab-${dataTestId}`
      }

      tabList.push(
        h('li',
          {
            ...{
              class: 'vue-tab',
              role: 'tab',
              onClick: e => this.switchTab(e, index, isDisabled),
              'aria-selected': this.selectedIndex === index ? "true" : "false",
              'aria-disabled': isDisabled ? "true" : "false",
              'data-text': content
            },
            ...tabOptions
          },
          content)
      )
    })
    return h('div', { class: 'vue-tabs', role: 'tabs' }, [h('ul', { class: 'vue-tablist', role: 'tablist' }, [this.$slots.left, tabList, this.$slots.right]), tabs[this.selectedIndex]])
  }
}

export const Tab = {
  name: "tab",
  props: ["title", "titleSlot", "disabled", "dataTestId"],
  render() {
    return h("div", { class: 'vue-tabpanel', role: 'tabpanel' }, this.$slots.default())
  }
}

export function install(Vue) {
  Vue.component(Tabs.name, Tabs)
  Vue.component(Tab.name, Tab)
}
