// External imports for registration.
import { defineComponent } from 'vue'
import * as Tabs from '@/lib/vue-slim-tabs/vue-slim-tabs.js'
import '@/lib/vue-slim-tabs/default.css'
import '@/lib/vue-context/dist/css/vue-context.css'
import 'splitpanes/dist/splitpanes.css'

// Internal imports.
import filters from '@/filters'
import fonts from '@/fonts'
import { install as icons } from '@/app/query-details/html-query-plan/icons/iconsMixin'
import { jolokiaService, mbeans, app as appService, gettext, $toggles } from '@/services'
import { internalHost } from '@/services/constants'
import { hasRole } from '@/auth'
import '@/store'
import '@/styles/main.css'
import { emitter } from '@/services/emitter'

// Globally register all global components

// Ensure this is in shared bundle.
import SvgLogoDark from '@/assets/svg/yb-logo-trans.svg'

// Setup jolokia.
jolokiaService.init()

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp

  // Pass on registrations.
  app.use(filters)
  app.use(fonts)
  app.use(gettext)
  app.use(icons)

  // Register components.
  app.component('SvgLogoDark', defineComponent(SvgLogoDark as any))
  app.use(Tabs)

  // Setup global emitter.
  app.config.globalProperties.$emitter = emitter

  // Setup mbeans as top-level var.
  app.config.globalProperties.$mbeans = mbeans

  // Setup feature toggle accessor as top-level var and make it reactive.
  app.config.globalProperties.$toggles = $toggles

  // Some app constants.
  app.config.globalProperties.$constants = {
    internalHost
  }

  // Some app messages used everywhere.
  app.config.globalProperties.$messages = {
    noPrivilege: 'You do not have privileges to perform this action. Please contact the administrator.'
  }

  // Add $hasRole global helper.
  app.config.globalProperties.$hasRole = hasRole

  // Setup notices.
  app.config.globalProperties.$notices = appService.notices

  // Setup global deployment mode.
  app.config.globalProperties.$deploymentMode = useRuntimeConfig()?.public?.deploymentMode || 'enterprise'

  // Setup docsite base.
  const vcsBranches = import.meta.env.VITE_VCS_BRANCHES
  const docsite = String(vcsBranches).match(/rel-.*/) ? 'https://docs.yellowbrick.com' : 'https://tester.yellowbrick.com'
  app.config.globalProperties.$docsite = docsite
})
