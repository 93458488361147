import revive_payload_client_YTGlmQWWT5 from "/opt/ybd/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/opt/ybd/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/opt/ybd/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/opt/ybd/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/opt/ybd/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/opt/ybd/ui/ym/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/opt/ybd/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/opt/ybd/ui/ym/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_DtfAVtogpk from "/opt/ybd/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/opt/ybd/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import chart_client_a5LfFlCPUx from "/opt/ybd/ui/shared/src/plugins/chart.client.js";
import floating_tdZmIJk62X from "/opt/ybd/ui/shared/src/plugins/floating.ts";
import misc_iBQPI8jlgt from "/opt/ybd/ui/shared/src/plugins/misc.ts";
import virtual_scroller_dnnCWqnzKw from "/opt/ybd/ui/shared/src/plugins/virtual-scroller.ts";
import _0_store_client_FkJqiGS2tA from "/opt/ybd/ui/ym/src/plugins/0.store.client.ts";
import _1_error_handler_IQw368bhfs from "/opt/ybd/ui/ym/src/plugins/1.error-handler.ts";
import _2_logrocket_client_LntWpCAbsO from "/opt/ybd/ui/ym/src/plugins/2.logrocket.client.ts";
import _3_init_Z8MLuwe7Yk from "/opt/ybd/ui/ym/src/plugins/3.init.ts";
import _4_main_ugPxjWNt9t from "/opt/ybd/ui/ym/src/plugins/4.main.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_AOuQ1DYzjk,
  plugin_DtfAVtogpk,
  chunk_reload_client_SeG0EjL5Ec,
  chart_client_a5LfFlCPUx,
  floating_tdZmIJk62X,
  misc_iBQPI8jlgt,
  virtual_scroller_dnnCWqnzKw,
  _0_store_client_FkJqiGS2tA,
  _1_error_handler_IQw368bhfs,
  _2_logrocket_client_LntWpCAbsO,
  _3_init_Z8MLuwe7Yk,
  _4_main_ugPxjWNt9t
]