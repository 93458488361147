// Internal imports.
import { install as renderInstall } from '@/utils/misc/vue-render'
import { install as modalInstall } from "@/utils/misc/modal"
import { install as dialogInstall } from '@/utils/misc/dialogs'
import { install as directivesInstall } from '@/directives'

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp

  // Modals and dialogs and directives
  modalInstall(app)
  dialogInstall(app)
  renderInstall(app)
  directivesInstall(app)
})
