import { defineComponent } from 'vue'
import VueClipboard from 'vue-clipboard2'

import YbClipboard from '@/components/YbClipboard.vue'
import { modal } from '@/utils/misc/modal'

const ClipboardConstructor = defineComponent(YbClipboard)

let $copyText = (text) => { throw new Error('Clipboard support not installed') }

function clipboardClickHandler(event) {
  const { promise, text, func, label, allowEvent } = this
  if (!allowEvent) {
    event.stopPropagation()
    event.preventDefault()
  }

  // Get the text to copy.
  (promise ? promise() : Promise.resolve(func ? func() : text))
    .then((content) => {
      const text = String(content)
      if (!text) {
        return
      }
      try {
        $copyText(text)
          .then(() => useDialogs().notifyClipboard(text, label))
          .catch(() => {
            modal('Yellowbrick Manager', 'Yellowbrick Manager needs to access the clipboard', 'Grant', ClipboardConstructor, { text, label })
          })
      } catch (e) {
        console.error('Could not copy to clipboard during error handling')
        console.error(e)
      }
    })
    .catch((e) => {
      console.error('Could not process copy promise for error handling')
      console.error(e)
    })
}

function install(el, binding, vnode) {
  // Get the promise binding.
  let promise, text, func
  if (binding.arg === 'promise') {
    promise = binding.value
  } else if (binding.arg === 'text' || typeof binding.arg === 'undefined') {
    text = binding.value
  } else if (binding.arg === 'function') {
    func = binding.value
  }

  // Get optional label.
  const label = el.getAttribute('clipboardLabel') || el.getAttribute('clipboard-label')

  // Get optional event flag.
  const allowEvent = typeof (el.getAttribute('allowEvent') || el.getAttribute('allow-event')) !== 'undefined'

  if (el.__clipboard_listener) {
    el.removeEventListener('click', el.__clipboard_listener)
  }
  el.addEventListener('click', (el.__clipboard_listener = clipboardClickHandler.bind({ promise, text, func, label, allowEvent })))
}

export default function(app) {
  app.use(VueClipboard)

  app.directive('yb-clipboard', {
    beforeMount: install,
    updated: install,
    unmounted(el) {
      el.removeEventListener('click', el.__clipboard_listener)
      delete el.__clipboard_listener
    }
  })

  $copyText = app.config.globalProperties.$copyText
}
