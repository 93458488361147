export default {
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'info'
    },
    dialogClasses: String,
    okLabel: String,
    cancelLabel: String,
    close: {
      type: Function,
      default: () => true
    },
    ok: {
      type: Function,
      default: () => true
    },
    valid: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    okActualLabel() {
      return this.okLabel || 'OK'
    },
    cancelActualLabel() {
      return this.cancelLabel || 'Cancel'
    }
  },
  mounted() {
    this.$emit('open', this)
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    async okClick() {
      try {
        const result = await this.ok()
        if (result) {
          this.close()
          this.onClose()
        }
      } catch (e) {
        console.error(e)
        this.$dialogs.error('An unexpected error occurred: ' + e)
      }
    }
  }
}
