import { default as _404EfC5nBBvCJMeta } from "/opt/ybd/ui/ym/src/pages/404.vue?macro=true";
import { default as aboutDJecBTiuvFMeta } from "/opt/ybd/ui/ym/src/pages/about.vue?macro=true";
import { default as activity8zdXSmh0T1Meta } from "/opt/ybd/ui/ym/src/pages/activity.vue?macro=true";
import { default as indexKzVt4kgklYMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/billing/index.vue?macro=true";
import { default as indexOzhdghJx5AMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/index.vue?macro=true";
import { default as indexcNtsrMLbwrMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/instances/index.vue?macro=true";
import { default as indexKMlXAYUD7cMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/license/index.vue?macro=true";
import { default as indexaoGsffZg8qMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/manage-access/index.vue?macro=true";
import { default as indexfNf8csZPx8Meta } from "/opt/ybd/ui/ym/src/pages/configuration/index/manager/index.vue?macro=true";
import { default as indexsTjoF5Jr4nMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/metering/index.vue?macro=true";
import { default as indexcRPm40vSPeMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/sites/index.vue?macro=true";
import { default as indexiipZCIQK5OMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index/sso/index.vue?macro=true";
import { default as index0WHnYH0hmVMeta } from "/opt/ybd/ui/ym/src/pages/configuration/index.vue?macro=true";
import { default as format_45datavPvxqVA4NnMeta } from "/opt/ybd/ui/ym/src/pages/data-wizard/index/format-data.vue?macro=true";
import { default as indexJ9UX49UNPVMeta } from "/opt/ybd/ui/ym/src/pages/data-wizard/index/index.vue?macro=true";
import { default as load_45datairklyOeqzoMeta } from "/opt/ybd/ui/ym/src/pages/data-wizard/index/load-data.vue?macro=true";
import { default as select_45data6shZhBzGU6Meta } from "/opt/ybd/ui/ym/src/pages/data-wizard/index/select-data.vue?macro=true";
import { default as indexETkBNxqRTQMeta } from "/opt/ybd/ui/ym/src/pages/data-wizard/index.vue?macro=true";
import { default as drivers_45toolsyOZRhGEWNoMeta } from "/opt/ybd/ui/ym/src/pages/drivers-tools.vue?macro=true";
import { default as feature_45flagsmlC0HCf3GqMeta } from "/opt/ybd/ui/ym/src/pages/feature-flags.vue?macro=true";
import { default as homezL9La0OqNzMeta } from "/opt/ybd/ui/ym/src/pages/home.vue?macro=true";
import { default as indexthrd5X7J7JMeta } from "/opt/ybd/ui/ym/src/pages/index.vue?macro=true";
import { default as indexiMyhU0QeWLMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/index.vue?macro=true";
import { default as indexBl8tLDfdz3Meta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/access-control/index.vue?macro=true";
import { default as backupXcVPlJJwldMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/backup.vue?macro=true";
import { default as loadCvF5nAK3KlMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/load.vue?macro=true";
import { default as queryqiBeV2BOOPMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/query.vue?macro=true";
import { default as restoreJdhye2xYi9Meta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/restore.vue?macro=true";
import { default as sessioniUMy6hx2XUMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/session.vue?macro=true";
import { default as unloadWQYwQ3qAvxMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/unload.vue?macro=true";
import { default as activityAhf7El38XRMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity.vue?macro=true";
import { default as _91cluster_id_93YRcTnKxqLqMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters/[cluster_id].vue?macro=true";
import { default as indexpeO6DOcytUMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters/index.vue?macro=true";
import { default as clustersXZakiWXdVpMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters.vue?macro=true";
import { default as configuration9OacNAKshAMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/configuration.vue?macro=true";
import { default as index1xW3Pco3RfMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id]/index.vue?macro=true";
import { default as _91schema_id_93dczrfN89cwMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id]/schemas/[schema_id].vue?macro=true";
import { default as _91database_id_939EFQf4DZwaMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id].vue?macro=true";
import { default as indexTsIqVK26R5Meta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/index.vue?macro=true";
import { default as databasesTHAfnPWX3CMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases.vue?macro=true";
import { default as execution_45timelineqGbaoSfUyAMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/execution-timeline.vue?macro=true";
import { default as external_45resourcesTkL18AgMtNMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/external-resources.vue?macro=true";
import { default as indexC0Xtn2qUfXMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/index.vue?macro=true";
import { default as licensezTasXjrVByMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/license.vue?macro=true";
import { default as reservationsbZ5E7046LWMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/reservations.vue?macro=true";
import { default as restrict_45accesslHMborQ2CHMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/restrict-access.vue?macro=true";
import { default as settings0XAhpVhWjzMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/settings.vue?macro=true";
import { default as shared_45servicesjyEBAuooFEMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/shared-services.vue?macro=true";
import { default as usage_45meteringT33PpPCVKfMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/usage-metering.vue?macro=true";
import { default as global_45rulesxg0ZeZh4JXMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/global-rules.vue?macro=true";
import { default as indexjQYLa4LchzMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/index.vue?macro=true";
import { default as _91wlm_profile_id_93oYEuPys7iGMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/profiles/[wlm_profile_id].vue?macro=true";
import { default as rule_45processingfw9444Z8TFMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/rule-processing.vue?macro=true";
import { default as workload_45managementyLxK4delRdMeta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management.vue?macro=true";
import { default as _91instance_id_93XTJCRtEI13Meta } from "/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id].vue?macro=true";
import { default as index3qcy7tjSXCMeta } from "/opt/ybd/ui/ym/src/pages/instances/index.vue?macro=true";
import { default as loginZ00GjElbUcMeta } from "/opt/ybd/ui/ym/src/pages/login.vue?macro=true";
import { default as not_45authorizedJnPjVPx0SzMeta } from "/opt/ybd/ui/ym/src/pages/not-authorized.vue?macro=true";
import { default as queryBqrgw3uLGEMeta } from "/opt/ybd/ui/ym/src/pages/query.vue?macro=true";
import { default as disclosurez42lQ1lK6VMeta } from "/opt/ybd/ui/ym/src/pages/test/disclosure.vue?macro=true";
import { default as tabsYQ1RPsNHbBMeta } from "/opt/ybd/ui/ym/src/pages/test/tabs.vue?macro=true";
import { default as TestTimelineu0Rzhtqv3cMeta } from "/opt/ybd/ui/ym/src/pages/TestTimeline.vue?macro=true";
import { default as thanksto2W9HOdN0Meta } from "/opt/ybd/ui/ym/src/pages/thanks.vue?macro=true";
import { default as token_45authenticated1yPzHdTYDrMeta } from "/opt/ybd/ui/ym/src/pages/token-authenticated.vue?macro=true";
import { default as welcome2Vx3Opr2T9Meta } from "/opt/ybd/ui/ym/src/pages/welcome.vue?macro=true";
export default [
  {
    name: _404EfC5nBBvCJMeta?.name ?? "404",
    path: _404EfC5nBBvCJMeta?.path ?? "/404",
    meta: _404EfC5nBBvCJMeta || {},
    alias: _404EfC5nBBvCJMeta?.alias || [],
    redirect: _404EfC5nBBvCJMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: aboutDJecBTiuvFMeta?.name ?? "about",
    path: aboutDJecBTiuvFMeta?.path ?? "/about",
    meta: aboutDJecBTiuvFMeta || {},
    alias: aboutDJecBTiuvFMeta?.alias || [],
    redirect: aboutDJecBTiuvFMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: activity8zdXSmh0T1Meta?.name ?? "activity",
    path: activity8zdXSmh0T1Meta?.path ?? "/activity",
    meta: activity8zdXSmh0T1Meta || {},
    alias: activity8zdXSmh0T1Meta?.alias || [],
    redirect: activity8zdXSmh0T1Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: index0WHnYH0hmVMeta?.name ?? undefined,
    path: index0WHnYH0hmVMeta?.path ?? "/configuration",
    meta: index0WHnYH0hmVMeta || {},
    alias: index0WHnYH0hmVMeta?.alias || [],
    redirect: index0WHnYH0hmVMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexKzVt4kgklYMeta?.name ?? "configuration-index-billing",
    path: indexKzVt4kgklYMeta?.path ?? "billing",
    meta: indexKzVt4kgklYMeta || {},
    alias: indexKzVt4kgklYMeta?.alias || [],
    redirect: indexKzVt4kgklYMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/billing/index.vue").then(m => m.default || m)
  },
  {
    name: indexOzhdghJx5AMeta?.name ?? "configuration-index",
    path: indexOzhdghJx5AMeta?.path ?? "",
    meta: indexOzhdghJx5AMeta || {},
    alias: indexOzhdghJx5AMeta?.alias || [],
    redirect: indexOzhdghJx5AMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexcNtsrMLbwrMeta?.name ?? "configuration-index-instances",
    path: indexcNtsrMLbwrMeta?.path ?? "instances",
    meta: indexcNtsrMLbwrMeta || {},
    alias: indexcNtsrMLbwrMeta?.alias || [],
    redirect: indexcNtsrMLbwrMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/instances/index.vue").then(m => m.default || m)
  },
  {
    name: indexKMlXAYUD7cMeta?.name ?? "configuration-index-license",
    path: indexKMlXAYUD7cMeta?.path ?? "license",
    meta: indexKMlXAYUD7cMeta || {},
    alias: indexKMlXAYUD7cMeta?.alias || [],
    redirect: indexKMlXAYUD7cMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/license/index.vue").then(m => m.default || m)
  },
  {
    name: indexaoGsffZg8qMeta?.name ?? "configuration-index-manage-access",
    path: indexaoGsffZg8qMeta?.path ?? "manage-access",
    meta: indexaoGsffZg8qMeta || {},
    alias: indexaoGsffZg8qMeta?.alias || [],
    redirect: indexaoGsffZg8qMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/manage-access/index.vue").then(m => m.default || m)
  },
  {
    name: indexfNf8csZPx8Meta?.name ?? "configuration-index-manager",
    path: indexfNf8csZPx8Meta?.path ?? "manager",
    meta: indexfNf8csZPx8Meta || {},
    alias: indexfNf8csZPx8Meta?.alias || [],
    redirect: indexfNf8csZPx8Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/manager/index.vue").then(m => m.default || m)
  },
  {
    name: indexsTjoF5Jr4nMeta?.name ?? "configuration-index-metering",
    path: indexsTjoF5Jr4nMeta?.path ?? "metering",
    meta: indexsTjoF5Jr4nMeta || {},
    alias: indexsTjoF5Jr4nMeta?.alias || [],
    redirect: indexsTjoF5Jr4nMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/metering/index.vue").then(m => m.default || m)
  },
  {
    name: indexcRPm40vSPeMeta?.name ?? "configuration-index-sites",
    path: indexcRPm40vSPeMeta?.path ?? "sites",
    meta: indexcRPm40vSPeMeta || {},
    alias: indexcRPm40vSPeMeta?.alias || [],
    redirect: indexcRPm40vSPeMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/sites/index.vue").then(m => m.default || m)
  },
  {
    name: indexiipZCIQK5OMeta?.name ?? "configuration-index-sso",
    path: indexiipZCIQK5OMeta?.path ?? "sso",
    meta: indexiipZCIQK5OMeta || {},
    alias: indexiipZCIQK5OMeta?.alias || [],
    redirect: indexiipZCIQK5OMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/configuration/index/sso/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexETkBNxqRTQMeta?.name ?? undefined,
    path: indexETkBNxqRTQMeta?.path ?? "/data-wizard",
    meta: indexETkBNxqRTQMeta || {},
    alias: indexETkBNxqRTQMeta?.alias || [],
    redirect: indexETkBNxqRTQMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/data-wizard/index.vue").then(m => m.default || m),
    children: [
  {
    name: format_45datavPvxqVA4NnMeta?.name ?? "data-wizard-index-format-data",
    path: format_45datavPvxqVA4NnMeta?.path ?? "format-data",
    meta: format_45datavPvxqVA4NnMeta || {},
    alias: format_45datavPvxqVA4NnMeta?.alias || [],
    redirect: format_45datavPvxqVA4NnMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/data-wizard/index/format-data.vue").then(m => m.default || m)
  },
  {
    name: indexJ9UX49UNPVMeta?.name ?? "data-wizard-index",
    path: indexJ9UX49UNPVMeta?.path ?? "",
    meta: indexJ9UX49UNPVMeta || {},
    alias: indexJ9UX49UNPVMeta?.alias || [],
    redirect: indexJ9UX49UNPVMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/data-wizard/index/index.vue").then(m => m.default || m)
  },
  {
    name: load_45datairklyOeqzoMeta?.name ?? "data-wizard-index-load-data",
    path: load_45datairklyOeqzoMeta?.path ?? "load-data",
    meta: load_45datairklyOeqzoMeta || {},
    alias: load_45datairklyOeqzoMeta?.alias || [],
    redirect: load_45datairklyOeqzoMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/data-wizard/index/load-data.vue").then(m => m.default || m)
  },
  {
    name: select_45data6shZhBzGU6Meta?.name ?? "data-wizard-index-select-data",
    path: select_45data6shZhBzGU6Meta?.path ?? "select-data",
    meta: select_45data6shZhBzGU6Meta || {},
    alias: select_45data6shZhBzGU6Meta?.alias || [],
    redirect: select_45data6shZhBzGU6Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/data-wizard/index/select-data.vue").then(m => m.default || m)
  }
]
  },
  {
    name: drivers_45toolsyOZRhGEWNoMeta?.name ?? "drivers-tools",
    path: drivers_45toolsyOZRhGEWNoMeta?.path ?? "/drivers-tools",
    meta: drivers_45toolsyOZRhGEWNoMeta || {},
    alias: drivers_45toolsyOZRhGEWNoMeta?.alias || [],
    redirect: drivers_45toolsyOZRhGEWNoMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/drivers-tools.vue").then(m => m.default || m)
  },
  {
    name: feature_45flagsmlC0HCf3GqMeta?.name ?? "feature-flags",
    path: feature_45flagsmlC0HCf3GqMeta?.path ?? "/feature-flags",
    meta: feature_45flagsmlC0HCf3GqMeta || {},
    alias: feature_45flagsmlC0HCf3GqMeta?.alias || [],
    redirect: feature_45flagsmlC0HCf3GqMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/feature-flags.vue").then(m => m.default || m)
  },
  {
    name: homezL9La0OqNzMeta?.name ?? "home",
    path: homezL9La0OqNzMeta?.path ?? "/home",
    meta: homezL9La0OqNzMeta || {},
    alias: homezL9La0OqNzMeta?.alias || [],
    redirect: homezL9La0OqNzMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexthrd5X7J7JMeta?.name ?? "index",
    path: indexthrd5X7J7JMeta?.path ?? "/",
    meta: indexthrd5X7J7JMeta || {},
    alias: indexthrd5X7J7JMeta?.alias || [],
    redirect: indexthrd5X7J7JMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3qcy7tjSXCMeta?.name ?? undefined,
    path: index3qcy7tjSXCMeta?.path ?? "/instances",
    meta: index3qcy7tjSXCMeta || {},
    alias: index3qcy7tjSXCMeta?.alias || [],
    redirect: index3qcy7tjSXCMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexiMyhU0QeWLMeta?.name ?? "instances-index",
    path: indexiMyhU0QeWLMeta?.path ?? "",
    meta: indexiMyhU0QeWLMeta || {},
    alias: indexiMyhU0QeWLMeta?.alias || [],
    redirect: indexiMyhU0QeWLMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/index.vue").then(m => m.default || m)
  },
  {
    name: _91instance_id_93XTJCRtEI13Meta?.name ?? undefined,
    path: _91instance_id_93XTJCRtEI13Meta?.path ?? "instance/:instance_id()",
    meta: _91instance_id_93XTJCRtEI13Meta || {},
    alias: _91instance_id_93XTJCRtEI13Meta?.alias || [],
    redirect: _91instance_id_93XTJCRtEI13Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id].vue").then(m => m.default || m),
    children: [
  {
    name: indexBl8tLDfdz3Meta?.name ?? "instances-index-instance-instance_id-access-control",
    path: indexBl8tLDfdz3Meta?.path ?? "access-control",
    meta: indexBl8tLDfdz3Meta || {},
    alias: indexBl8tLDfdz3Meta?.alias || [],
    redirect: indexBl8tLDfdz3Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/access-control/index.vue").then(m => m.default || m)
  },
  {
    name: activityAhf7El38XRMeta?.name ?? "instances-index-instance-instance_id-activity",
    path: activityAhf7El38XRMeta?.path ?? "activity",
    meta: activityAhf7El38XRMeta || {},
    alias: activityAhf7El38XRMeta?.alias || [],
    redirect: activityAhf7El38XRMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity.vue").then(m => m.default || m),
    children: [
  {
    name: backupXcVPlJJwldMeta?.name ?? "instances-index-instance-instance_id-activity-backup",
    path: backupXcVPlJJwldMeta?.path ?? "backup",
    meta: backupXcVPlJJwldMeta || {},
    alias: backupXcVPlJJwldMeta?.alias || [],
    redirect: backupXcVPlJJwldMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/backup.vue").then(m => m.default || m)
  },
  {
    name: loadCvF5nAK3KlMeta?.name ?? "instances-index-instance-instance_id-activity-load",
    path: loadCvF5nAK3KlMeta?.path ?? "load",
    meta: loadCvF5nAK3KlMeta || {},
    alias: loadCvF5nAK3KlMeta?.alias || [],
    redirect: loadCvF5nAK3KlMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/load.vue").then(m => m.default || m)
  },
  {
    name: queryqiBeV2BOOPMeta?.name ?? "instances-index-instance-instance_id-activity-query",
    path: queryqiBeV2BOOPMeta?.path ?? "query",
    meta: queryqiBeV2BOOPMeta || {},
    alias: queryqiBeV2BOOPMeta?.alias || [],
    redirect: queryqiBeV2BOOPMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/query.vue").then(m => m.default || m)
  },
  {
    name: restoreJdhye2xYi9Meta?.name ?? "instances-index-instance-instance_id-activity-restore",
    path: restoreJdhye2xYi9Meta?.path ?? "restore",
    meta: restoreJdhye2xYi9Meta || {},
    alias: restoreJdhye2xYi9Meta?.alias || [],
    redirect: restoreJdhye2xYi9Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/restore.vue").then(m => m.default || m)
  },
  {
    name: sessioniUMy6hx2XUMeta?.name ?? "instances-index-instance-instance_id-activity-session",
    path: sessioniUMy6hx2XUMeta?.path ?? "session",
    meta: sessioniUMy6hx2XUMeta || {},
    alias: sessioniUMy6hx2XUMeta?.alias || [],
    redirect: sessioniUMy6hx2XUMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/session.vue").then(m => m.default || m)
  },
  {
    name: unloadWQYwQ3qAvxMeta?.name ?? "instances-index-instance-instance_id-activity-unload",
    path: unloadWQYwQ3qAvxMeta?.path ?? "unload",
    meta: unloadWQYwQ3qAvxMeta || {},
    alias: unloadWQYwQ3qAvxMeta?.alias || [],
    redirect: unloadWQYwQ3qAvxMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/activity/unload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: clustersXZakiWXdVpMeta?.name ?? undefined,
    path: clustersXZakiWXdVpMeta?.path ?? "clusters",
    meta: clustersXZakiWXdVpMeta || {},
    alias: clustersXZakiWXdVpMeta?.alias || [],
    redirect: clustersXZakiWXdVpMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters.vue").then(m => m.default || m),
    children: [
  {
    name: _91cluster_id_93YRcTnKxqLqMeta?.name ?? "instances-index-instance-instance_id-clusters-cluster_id",
    path: _91cluster_id_93YRcTnKxqLqMeta?.path ?? ":cluster_id()",
    meta: _91cluster_id_93YRcTnKxqLqMeta || {},
    alias: _91cluster_id_93YRcTnKxqLqMeta?.alias || [],
    redirect: _91cluster_id_93YRcTnKxqLqMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters/[cluster_id].vue").then(m => m.default || m)
  },
  {
    name: indexpeO6DOcytUMeta?.name ?? "instances-index-instance-instance_id-clusters",
    path: indexpeO6DOcytUMeta?.path ?? "",
    meta: indexpeO6DOcytUMeta || {},
    alias: indexpeO6DOcytUMeta?.alias || [],
    redirect: indexpeO6DOcytUMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/clusters/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: configuration9OacNAKshAMeta?.name ?? "instances-index-instance-instance_id-configuration",
    path: configuration9OacNAKshAMeta?.path ?? "configuration",
    meta: configuration9OacNAKshAMeta || {},
    alias: configuration9OacNAKshAMeta?.alias || [],
    redirect: configuration9OacNAKshAMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/configuration.vue").then(m => m.default || m)
  },
  {
    name: databasesTHAfnPWX3CMeta?.name ?? undefined,
    path: databasesTHAfnPWX3CMeta?.path ?? "databases",
    meta: databasesTHAfnPWX3CMeta || {},
    alias: databasesTHAfnPWX3CMeta?.alias || [],
    redirect: databasesTHAfnPWX3CMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases.vue").then(m => m.default || m),
    children: [
  {
    name: _91database_id_939EFQf4DZwaMeta?.name ?? undefined,
    path: _91database_id_939EFQf4DZwaMeta?.path ?? "database/:database_id()",
    meta: _91database_id_939EFQf4DZwaMeta || {},
    alias: _91database_id_939EFQf4DZwaMeta?.alias || [],
    redirect: _91database_id_939EFQf4DZwaMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id].vue").then(m => m.default || m),
    children: [
  {
    name: index1xW3Pco3RfMeta?.name ?? "instances-index-instance-instance_id-databases-database-database_id",
    path: index1xW3Pco3RfMeta?.path ?? "",
    meta: index1xW3Pco3RfMeta || {},
    alias: index1xW3Pco3RfMeta?.alias || [],
    redirect: index1xW3Pco3RfMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91schema_id_93dczrfN89cwMeta?.name ?? "instances-index-instance-instance_id-databases-database-database_id-schemas-schema_id",
    path: _91schema_id_93dczrfN89cwMeta?.path ?? "schemas/:schema_id()",
    meta: _91schema_id_93dczrfN89cwMeta || {},
    alias: _91schema_id_93dczrfN89cwMeta?.alias || [],
    redirect: _91schema_id_93dczrfN89cwMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/database/[database_id]/schemas/[schema_id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexTsIqVK26R5Meta?.name ?? "instances-index-instance-instance_id-databases",
    path: indexTsIqVK26R5Meta?.path ?? "",
    meta: indexTsIqVK26R5Meta || {},
    alias: indexTsIqVK26R5Meta?.alias || [],
    redirect: indexTsIqVK26R5Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/databases/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: execution_45timelineqGbaoSfUyAMeta?.name ?? "instances-index-instance-instance_id-execution-timeline",
    path: execution_45timelineqGbaoSfUyAMeta?.path ?? "execution-timeline",
    meta: execution_45timelineqGbaoSfUyAMeta || {},
    alias: execution_45timelineqGbaoSfUyAMeta?.alias || [],
    redirect: execution_45timelineqGbaoSfUyAMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/execution-timeline.vue").then(m => m.default || m)
  },
  {
    name: external_45resourcesTkL18AgMtNMeta?.name ?? "instances-index-instance-instance_id-external-resources",
    path: external_45resourcesTkL18AgMtNMeta?.path ?? "external-resources",
    meta: external_45resourcesTkL18AgMtNMeta || {},
    alias: external_45resourcesTkL18AgMtNMeta?.alias || [],
    redirect: external_45resourcesTkL18AgMtNMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/external-resources.vue").then(m => m.default || m)
  },
  {
    name: indexC0Xtn2qUfXMeta?.name ?? "instances-index-instance-instance_id",
    path: indexC0Xtn2qUfXMeta?.path ?? "",
    meta: indexC0Xtn2qUfXMeta || {},
    alias: indexC0Xtn2qUfXMeta?.alias || [],
    redirect: indexC0Xtn2qUfXMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/index.vue").then(m => m.default || m)
  },
  {
    name: licensezTasXjrVByMeta?.name ?? "instances-index-instance-instance_id-license",
    path: licensezTasXjrVByMeta?.path ?? "license",
    meta: licensezTasXjrVByMeta || {},
    alias: licensezTasXjrVByMeta?.alias || [],
    redirect: licensezTasXjrVByMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/license.vue").then(m => m.default || m)
  },
  {
    name: reservationsbZ5E7046LWMeta?.name ?? "instances-index-instance-instance_id-reservations",
    path: reservationsbZ5E7046LWMeta?.path ?? "reservations",
    meta: reservationsbZ5E7046LWMeta || {},
    alias: reservationsbZ5E7046LWMeta?.alias || [],
    redirect: reservationsbZ5E7046LWMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/reservations.vue").then(m => m.default || m)
  },
  {
    name: restrict_45accesslHMborQ2CHMeta?.name ?? "instances-index-instance-instance_id-restrict-access",
    path: restrict_45accesslHMborQ2CHMeta?.path ?? "restrict-access",
    meta: restrict_45accesslHMborQ2CHMeta || {},
    alias: restrict_45accesslHMborQ2CHMeta?.alias || [],
    redirect: restrict_45accesslHMborQ2CHMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/restrict-access.vue").then(m => m.default || m)
  },
  {
    name: settings0XAhpVhWjzMeta?.name ?? "instances-index-instance-instance_id-settings",
    path: settings0XAhpVhWjzMeta?.path ?? "settings",
    meta: settings0XAhpVhWjzMeta || {},
    alias: settings0XAhpVhWjzMeta?.alias || [],
    redirect: settings0XAhpVhWjzMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/settings.vue").then(m => m.default || m)
  },
  {
    name: shared_45servicesjyEBAuooFEMeta?.name ?? "instances-index-instance-instance_id-shared-services",
    path: shared_45servicesjyEBAuooFEMeta?.path ?? "shared-services",
    meta: shared_45servicesjyEBAuooFEMeta || {},
    alias: shared_45servicesjyEBAuooFEMeta?.alias || [],
    redirect: shared_45servicesjyEBAuooFEMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/shared-services.vue").then(m => m.default || m)
  },
  {
    name: usage_45meteringT33PpPCVKfMeta?.name ?? "instances-index-instance-instance_id-usage-metering",
    path: usage_45meteringT33PpPCVKfMeta?.path ?? "usage-metering",
    meta: usage_45meteringT33PpPCVKfMeta || {},
    alias: usage_45meteringT33PpPCVKfMeta?.alias || [],
    redirect: usage_45meteringT33PpPCVKfMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/usage-metering.vue").then(m => m.default || m)
  },
  {
    name: workload_45managementyLxK4delRdMeta?.name ?? undefined,
    path: workload_45managementyLxK4delRdMeta?.path ?? "workload-management",
    meta: workload_45managementyLxK4delRdMeta || {},
    alias: workload_45managementyLxK4delRdMeta?.alias || [],
    redirect: workload_45managementyLxK4delRdMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management.vue").then(m => m.default || m),
    children: [
  {
    name: global_45rulesxg0ZeZh4JXMeta?.name ?? "instances-index-instance-instance_id-workload-management-global-rules",
    path: global_45rulesxg0ZeZh4JXMeta?.path ?? "global-rules",
    meta: global_45rulesxg0ZeZh4JXMeta || {},
    alias: global_45rulesxg0ZeZh4JXMeta?.alias || [],
    redirect: global_45rulesxg0ZeZh4JXMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/global-rules.vue").then(m => m.default || m)
  },
  {
    name: indexjQYLa4LchzMeta?.name ?? "instances-index-instance-instance_id-workload-management",
    path: indexjQYLa4LchzMeta?.path ?? "",
    meta: indexjQYLa4LchzMeta || {},
    alias: indexjQYLa4LchzMeta?.alias || [],
    redirect: indexjQYLa4LchzMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/index.vue").then(m => m.default || m)
  },
  {
    name: _91wlm_profile_id_93oYEuPys7iGMeta?.name ?? "instances-index-instance-instance_id-workload-management-profiles-wlm_profile_id",
    path: _91wlm_profile_id_93oYEuPys7iGMeta?.path ?? "profiles/:wlm_profile_id()",
    meta: _91wlm_profile_id_93oYEuPys7iGMeta || {},
    alias: _91wlm_profile_id_93oYEuPys7iGMeta?.alias || [],
    redirect: _91wlm_profile_id_93oYEuPys7iGMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/profiles/[wlm_profile_id].vue").then(m => m.default || m)
  },
  {
    name: rule_45processingfw9444Z8TFMeta?.name ?? "instances-index-instance-instance_id-workload-management-rule-processing",
    path: rule_45processingfw9444Z8TFMeta?.path ?? "rule-processing",
    meta: rule_45processingfw9444Z8TFMeta || {},
    alias: rule_45processingfw9444Z8TFMeta?.alias || [],
    redirect: rule_45processingfw9444Z8TFMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/instances/index/instance/[instance_id]/workload-management/rule-processing.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: loginZ00GjElbUcMeta?.name ?? "login",
    path: loginZ00GjElbUcMeta?.path ?? "/login",
    meta: loginZ00GjElbUcMeta || {},
    alias: loginZ00GjElbUcMeta?.alias || [],
    redirect: loginZ00GjElbUcMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: not_45authorizedJnPjVPx0SzMeta?.name ?? "not-authorized",
    path: not_45authorizedJnPjVPx0SzMeta?.path ?? "/not-authorized",
    meta: not_45authorizedJnPjVPx0SzMeta || {},
    alias: not_45authorizedJnPjVPx0SzMeta?.alias || [],
    redirect: not_45authorizedJnPjVPx0SzMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/not-authorized.vue").then(m => m.default || m)
  },
  {
    name: queryBqrgw3uLGEMeta?.name ?? "query",
    path: queryBqrgw3uLGEMeta?.path ?? "/query",
    meta: queryBqrgw3uLGEMeta || {},
    alias: queryBqrgw3uLGEMeta?.alias || [],
    redirect: queryBqrgw3uLGEMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/query.vue").then(m => m.default || m)
  },
  {
    name: disclosurez42lQ1lK6VMeta?.name ?? "test-disclosure",
    path: disclosurez42lQ1lK6VMeta?.path ?? "/test/disclosure",
    meta: disclosurez42lQ1lK6VMeta || {},
    alias: disclosurez42lQ1lK6VMeta?.alias || [],
    redirect: disclosurez42lQ1lK6VMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/test/disclosure.vue").then(m => m.default || m)
  },
  {
    name: tabsYQ1RPsNHbBMeta?.name ?? "test-tabs",
    path: tabsYQ1RPsNHbBMeta?.path ?? "/test/tabs",
    meta: tabsYQ1RPsNHbBMeta || {},
    alias: tabsYQ1RPsNHbBMeta?.alias || [],
    redirect: tabsYQ1RPsNHbBMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/test/tabs.vue").then(m => m.default || m)
  },
  {
    name: TestTimelineu0Rzhtqv3cMeta?.name ?? "TestTimeline",
    path: TestTimelineu0Rzhtqv3cMeta?.path ?? "/TestTimeline",
    meta: TestTimelineu0Rzhtqv3cMeta || {},
    alias: TestTimelineu0Rzhtqv3cMeta?.alias || [],
    redirect: TestTimelineu0Rzhtqv3cMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/TestTimeline.vue").then(m => m.default || m)
  },
  {
    name: thanksto2W9HOdN0Meta?.name ?? "thanks",
    path: thanksto2W9HOdN0Meta?.path ?? "/thanks",
    meta: thanksto2W9HOdN0Meta || {},
    alias: thanksto2W9HOdN0Meta?.alias || [],
    redirect: thanksto2W9HOdN0Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/thanks.vue").then(m => m.default || m)
  },
  {
    name: token_45authenticated1yPzHdTYDrMeta?.name ?? "token-authenticated",
    path: token_45authenticated1yPzHdTYDrMeta?.path ?? "/token-authenticated",
    meta: token_45authenticated1yPzHdTYDrMeta || {},
    alias: token_45authenticated1yPzHdTYDrMeta?.alias || [],
    redirect: token_45authenticated1yPzHdTYDrMeta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/token-authenticated.vue").then(m => m.default || m)
  },
  {
    name: welcome2Vx3Opr2T9Meta?.name ?? "welcome",
    path: welcome2Vx3Opr2T9Meta?.path ?? "/welcome",
    meta: welcome2Vx3Opr2T9Meta || {},
    alias: welcome2Vx3Opr2T9Meta?.alias || [],
    redirect: welcome2Vx3Opr2T9Meta?.redirect,
    component: () => import("/opt/ybd/ui/ym/src/pages/welcome.vue").then(m => m.default || m)
  }
]