// External imports for registration.
import { createApolloProvider } from '@vue/apollo-option'

import defaultClient from '@/apollo'
import useAuthenticationService from '@/auth'

export default defineNuxtPlugin(async (nuxtApp) => {
  const app = nuxtApp.vueApp

  // Create an apollo provider
  const apolloProvider = createApolloProvider({
    defaultClient
  })

  await useAuthenticationService()
  app.use(apolloProvider)
})
