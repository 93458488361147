import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

export default defineNuxtPlugin((nuxtApp) => {
  // Setup floating vue.
  nuxtApp.vueApp.use(FloatingVue, {
    placement: 'top',
    delay: 0,
    distance: 10,
    container: 'body',
    boundary: undefined,
    autoHide: true,
    disposeTimeout: 5000,
    themes: {
      tooltip: {
        html: true,
        triggers: ['hover', 'focus'],
        hideTriggers: (triggers: any) => [...triggers, 'click'],
        loadingContent: '...'
      },
      dropdown: {
        placement: 'bottom',
        delay: 0,
        triggers: ['click'],
        distance: 0,
        container: 'body',
        boundary: undefined,
        autoHide: true,
        handleResize: true
      }
    }
  })
})
