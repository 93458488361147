function beforeMount(el, binding, vnode) {
  // Watch for the mutations
  if (!!vnode.context && !vnode.context.$documentClassChangeObserver) {
    vnode.context.$documentClassChangeObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          const classList = document.documentElement.classList
          if (vnode.componentInstance) {
            vnode.componentInstance.$emit('document-class-change', classList)
          } else {
            vnode.elm.dispatchEvent(new CustomEvent('document-class-change', classList))
          }
        }
      })
    })
    vnode.context.$documentClassChangeObserver.observe(document.documentElement, { attributes: true })
  }
}

function unmounted(el, binding, vnode) {
  // Remove watch for the mutations
  if (!!vnode.context && !!vnode.context.$documentClassChangeObserver) {
    vnode.context.$documentClassChangeObserver.disconnect()
    delete vnode.context.$documentClassChangeObserver
  }
}

export default function(app) {
  app.directive('document-class-watcher', {
    beforeMount,
    unmounted
  })
}
