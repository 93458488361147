/**
 * What?  v-resize directive
 * Originally was from this: ttps://github.com/bensladden/vue-element-resize-detector
 *
 * But that doesn't work for us any more
 * Why?
 *  - need an esm version (FU webpack!)
 *  - need to NOT mutate the dom in a way that is CSP-incompatible (style-attr inline violation)
 */

// this occurs once when the directive is attached to the element.
function beforeMount(element, binding, vnode) {
  element.__resizeObserver = new ResizeObserver((entries) => {
    const width = element.offsetWidth
    const height = element.offsetHeight
    const component = vnode.componentInstance || vnode.component || vnode.context
    if (!!component && (typeof component.$emit === 'function')) {
      component.$emit("resize", { detail: { width, height } })
    } else {
      const el = (vnode.elm || vnode.el)
      if (!!el && (typeof el.dispatchEvent === 'function')) {
        el.dispatchEvent(
          new CustomEvent("resize", { detail: { width, height } })
        )
      }
    }
  })
  element.__resizeObserver.observe(element)
}
function unmounted(element) {
  element.__resizeObserver.unobserve(element)
}

export default function(app) {
  app.directive("resize", {
    beforeMount,
    unmounted
  })
}
