import { Chart, registerables } from 'chart.js'
import Color from 'color'
import 'chartjs-adapter-luxon'

export default defineNuxtPlugin(() => {
  Chart.register(...registerables)

  Chart.register({
    id: 'ybgradient',

    afterLayout(chart) {
      const {
        ctx,
        chartArea,
        options: {
          ybgradient: {
            gradientFill
          } = {}
        }
      } = chart

      // Do we have an area?
      if (!chartArea || (chartArea.right <= chartArea.left) || (chartArea.bottom <= chartArea.top)) {
        return
      }

      // Grab the option for ybgradient
      if (!gradientFill) {
        return
      }

      // Create gradient with color stops full of color near the top, then drop to background color
      const dark = document.documentElement.classList.contains('dark')
      const gradientStroke = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      gradientStroke.addColorStop(1.0, Color(gradientFill).hex())
      if (dark) {
        gradientStroke.addColorStop(0.9, Color(gradientFill).darken(0.15).hex())
        gradientStroke.addColorStop(0.8, Color(gradientFill).darken(0.15).darken(0.15).hex())
        gradientStroke.addColorStop(0.0, Color('rgba(0, 0, 0, 0)').hex())
      } else {
        gradientStroke.addColorStop(0.9, Color(gradientFill).lighten(0.15).hex())
        gradientStroke.addColorStop(0.8, Color(gradientFill).lighten(0.15).lighten(0.15).hex())
        gradientStroke.addColorStop(0.0, Color('rgba(255,255,255,0)').hex())
      }

      // Install the gradient as background color
      chart.data.datasets.forEach((dataset, _index) => {
        dataset.backgroundColor = gradientStroke
      })
    }
  })
})
